import { HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { EditorApiService } from '@libs/editor'
import { CUSTOM_ERROR_MSG, RAW_BODY } from '@libs/ng-core/constants'
import { RestService } from '@libs/ng-core/services/rest.service'
import { CreateMaterialParams, CredentialReq, IBaseRes, IDataRes, IPaginatedListRes, IProject, IProjectPaginated, LoginRes } from '@libs/payload'

@Injectable({ providedIn: 'root' })
export class ApiService extends RestService implements EditorApiService {
  /* ------------------------- Auth -----------------------------------*/
  login(payload: CredentialReq): Observable<IDataRes<LoginRes>> {
    return this.http.post<IDataRes<LoginRes>>('/api/v1/auth/credential/login', payload, {
      context: new HttpContext().set(RAW_BODY, true).set(CUSTOM_ERROR_MSG, true)
    })
  }

  register(payload: CredentialReq): Observable<IBaseRes> {
    return this.http.post<IBaseRes>('/api/v1/auth/credential/register', payload, { context: new HttpContext().set(CUSTOM_ERROR_MSG, true) })
  }

  logout(): Observable<IBaseRes> {
    return this.http.get<IBaseRes>('/api/v1/auth/logout')
  }

  /* ------------------------- Project -----------------------------------*/

  getProjects(filter: IProjectPaginated): Observable<IPaginatedListRes<IProject>> {
    const params = Object.fromEntries(Object.entries(filter).map(([key, value]) => [key, value.toString()]))
    return this.http.get<IPaginatedListRes<IProject>>('/api/v1/projects', { params })
  }

  moveToRecycle(id: string): Observable<IBaseRes> {
    return this.http.patch<IBaseRes>(`/api/v1/projects/${id}`, { deleted: true })
  }

  duplicateProject(id: string): Observable<IProject> {
    return this.http.get<IProject>(`/api/v1/projects/${id}/duplicate`)
  }

  deleteProject(id: string): Observable<IBaseRes> {
    return this.http.delete<IBaseRes>(`/api/v1/projects/${id}`)
  }

  /* ------------------------- Material -----------------------------------*/
  saveAsMaterial(elements: CreateMaterialParams[]): Observable<IBaseRes> {
    return this.http.post<IBaseRes>('/api/v1/editor/material', elements)
  }
}
